.call-floating-button {
    background-image: linear-gradient(to right, #29e36a, #3387EE);
    border-radius: 15px;
    box-shadow: 0 2px 20px 0 #3387EE;
    height: 15vh;
}
.api-log-search-box {
    border: 1px solid var(--primary);
    border-radius: 10px;
    background-color: #fff;
}
.calling {
    font-size: 18px;
    color: #fff;
    font-weight: bolder;
}

.time {
    font-size: 12px;
    color: #fff;
    font-weight: 700;
}