.os-dropdown-trigger {
  position: relative;
  cursor: pointer;
  &.over {
    .os-dropdown {
      visibility: visible;
      opacity: 1;
      transform: translate3d(100%, 100%, 0);
    }
  }
  &.os-dropdown-position-center {
    .os-dropdown {
      right: 50%;
      transform: translate3d(50%, 110%, 0);
    }
    &.over {
      .os-dropdown {
        transform: translate3d(50%, 100%, 0);
      }
    }
  }
  &.os-dropdown-position-left {
    .os-dropdown {
      right: 0px;
      transform: translate3d(0%, 110%, 0);
    }
    &.over {
      .os-dropdown {
        transform: translate3d(0%, 100%, 0);
      }
    }
  }
  &.os-dropdown-position-right {
    .os-dropdown {
      right: 100%;
      transform: translate3d(100%, 110%, 0);
    }
    &.over {
      .os-dropdown {
        transform: translate3d(100%, 100%, 0);
      }
    }
  }
  &.os-dropdown-position-right-center {
    .os-dropdown {
      right: 0%;
      bottom: 50%;
      transform: translate3d(110%, 50%, 0);
    }
    &.over {
      .os-dropdown {
        transform: translate3d(100%, 50%, 0);
      }
    }
  }
  &.os-dropdown-position-left-center {
    .os-dropdown { 
      right: 100%;
      bottom: 50%;
      transform: translate3d(-10%, 50%, 0);
    }
    &.over {
      .os-dropdown { 
        transform: translate3d(0%, 50%, 0);
      }
    }
  }
}

.os-dropdown {
  background-color: $primary;
  color: #fff;
  padding: 20px 15px;
  position: absolute;
  z-index: 999;
  bottom: 5px;
  right: 0px;
  visibility: hidden;
  transition: all 0.2s ease;
  transform: translate3d(100%, 110%, 0);
  opacity: 0;
  border-radius: $border-radius;
  box-shadow: 0px 10px 25px 0px rgba(26, 32, 39, 0.1);
  overflow: hidden;
  font-size: $font-size-base * 1;
  text-align: left;
  > .icon-w {
    position: absolute;
    top: -30px;
    right: -30px;
    color: rgba(0,0,0,0.08);
    font-size: 100px;
  }
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    position: relative;
    li {
      border-bottom: 1px solid rgba(255,255,255,0.1);
      &:last-child {
        border-bottom: none;
      }
      a {
        display: block;
        white-space: nowrap;
        padding: 10px 10px 10px 10px;
        line-height: 1;
        color: #fff;
        font-size: $font-size-base * 1;
        &:hover {
          text-decoration: none;
        }
        i {
          color: #fff;
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;
          font-size: 22px;
          transition: all 0.2s ease;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          color: #fff; 
          font-size: $font-size-base * 1;
          transition: all 0.2s ease;
        }
        i + span {
          padding-right: 10px;
        }
        &:hover {
          i {
            color: #fff;
            transform: scale(1.2);
          }
          span {
            transform: translateX(3px);
          }
        }
      }
    }
  }

  &.message-list {
    padding: 15px 10px;
    li {
      border-bottom: 1px solid rgba(255,255,255,0.2);
      &:last-child {
        border-bottom: none;
      }
    }
    li > a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 15px;
      &:hover {
        background-color: rgba(124, 137, 234, 0.07);
        .user-avatar-w {
          img {
            transform: scale(1.1);
          }
        }
        .message-content {
          transform: translateX(3px);
        }
      }
      .user-avatar-w {
        flex: 0 0 40px;
        margin-right: 15px;
        img {
          width: 40px;
          border-radius: 30px;
          height: auto;
          transition: all 0.2s ease;
          display: block;
        }
      }
      .message-content {
        transition: all 0.2s ease;
        .message-from {
          color: #fff;
          margin-bottom: 5px;
        }
        .message-title {
          color: rgba(255,255,255,0.6);
          font-size: $font-size-base * 0.6;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-top: 2px;
          margin-bottom: 0px;
        }
      }
    }
  }
  &.light {
    background-color: #fff;
    box-shadow: 0px 5px 75px 0px rgba(12, 76, 140, 0.21), 0px 3px 7px 0px rgba(12, 76, 140, 0.14);

    &.message-list {
      > .icon-w {
        color: rgba($primary, 0.1);
      }
      li {
        border-bottom: 1px solid rgba(113, 133, 171, 0.09);
        &:last-child {
          border-bottom: none;
        }
      }
      li > a {
        .message-content {
          .message-from {
            color: $headings-color;
          }
          .message-title {
            color: $primary;
          }
        }
      }
    }
  }

}