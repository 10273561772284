.order-box {
  @extend .box-style;
  padding: 30px;
  .order-details-box {
  	display: flex;
  	justify-content: space-between;
  	margin-bottom: 20px;
  	align-items: center;
    .order-main-info {
      span {
      	display: block;
      	color: $text-muted;
      	line-height: 1.3;
      }
      strong {
      	display: block;
      	font-size: $font-size-xl;
      	line-height: 1.3;
      }
    }
    .order-sub-info {
      span {
      	display: block;
      	color: $text-muted;
      	line-height: 1.3;
      	font-size: $font-size-xs;
      }
      strong {
      	display: block;
      	font-size: $font-size-base;
      	line-height: 1.3;
      }
    }
  }

  .order-controls {
  	padding: 10px;
  	margin-bottom: 20px;
  	.form-group {
  		margin-right: 15px;
  		padding-right: 15px;
  		border-right: 1px solid rgba(0,0,0,0.05);
  		label {
  			margin-right: 5px;
  		}
  		&:last-child {
  			border-right: none;
  			margin-right: 0px;
  			padding-right: 0px;
  			margin-left: auto;
  		}
  	}
  }

  .order-items-table {
  	margin-bottom: 40px;
  	padding-bottom: 20px;
  	border-bottom: 1px solid rgba(0,0,0,0.1);
  	.product-image {
  		width: 70px;
  		height: 70px;
  		background-size: contain;
  		background-repeat: no-repeat;
  		background-position: center center;
  	}
  	.product-name {
  		font-weight: $font-weight-bold;
  		font-size: $font-size-lg;
  		line-height: 1.3;
  	}
  	.product-remove-btn {
			color: #E08989;
			font-size: 16px;
  	}
  	.product-details {
			color: $text-muted;
			font-size: $font-size-sm;
			strong {
				color: $body-color;
			}
			.color-box {
				width: 10px;
				height: 10px;
				display: inline-block;
				margin-left: 5px;
				margin-right: 10px;
			}
  	}
  	.product-price {
  		font-weight: $font-weight-bold;
  		font-size: $font-size-lg
  	}
  	.quantity-input {
  		.input-group-text {
  			padding-left: 5px!important;
  			padding-right: 5px!important;
  		}
  		.form-control {
	  		flex: 0 0 45px;
	  		text-align: center;
	  		font-weight: $font-weight-bold;
  		}
  	}
  }
  .order-section-heading {
  	border-bottom: 1px solid rgba(0,0,0,0.05);
  	padding-bottom: 10px;
  	margin-bottom: 15px;
  }
}

.ecommerce-customer-info {
  @extend .box-style;
  padding: 30px;
  .ecommerce-customer-main-info {
		text-align: center;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid rgba(0,0,0,0.05);
		.ecc-avatar img {
			width: 90px;
			height: 90px;
			background-size: cover;
			background-position: center center;
			border-radius: 50%;
			background-color: #fff;
			margin: 0px auto;
			box-shadow: 0px 0px 0px 10px #fff;
		}
		.ecc-name {
			margin-top: 10px;
  		font-weight: $font-weight-bold;
  		font-size: $font-size-lg;
		}
  }
  .ecommerce-customer-sub-info {
		margin-bottom: 30px;
  }
	.ecc-sub-info-row {
		margin-bottom: 10px;
		& + .ecc-sub-info-row {
			padding-top: 10px;
			border-top: 1px solid rgba(0,0,0,0.05);

		}
		.sub-info-label {
			display: block;
			color: $text-muted;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: $font-size-sm;
			margin-bottom: 4px;
		}
		.sub-info-value {
			display: block;
			img {
				margin-right: 5px;
			}
			span {
			}
		}
	}
  .os-tabs-controls {
  	.nav {
  		flex-wrap: nowrap;
  	}
  	.nav-link {
			background: none;
  		white-space: nowrap;
  		padding: 10px 0px;
			&:focus {
				outline: 0
			}
		}
  }
}

.order-summary-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&.as-total {
		.order-summary-label {
			font-weight: $font-weight-bold;
			font-size: $font-size-lg;
			color: $body-color;
		}
		.order-summary-value {
			font-weight: $font-weight-bold;
			font-size: $font-size-xl;
		}
	}
	.order-summary-label {
		color: $text-muted;
		strong {
			display: block;
			color: $body-color;
			font-size: $font-size-sm;
		}
	}
	.order-summary-value {
		font-weight: $font-weight-bold;
	}
	& + .order-summary-row {
		border-top: 1px solid rgba(0,0,0,0.05);
		padding-top: 5px;
		margin-top: 5px;
		&.as-total {
			margin-top: 20px;
			padding-top: 10px;
			border-top: 3px solid #222;
		}
	}
}