.popover {
  font-size: $font-size-base * 1.1;
  box-shadow: $popover-box-shadow;


}


// Offset the popover to account for the popover arrow
.popover-header {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: $font-size-base;
  color: #fff;
}