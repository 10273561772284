.search-with-suggestions-w {
	display: none;
	&.active {
		display: block;
	}
	.search-with-suggestions-modal {
		background-color: #fff;
		border-radius: $border-radius;
		box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
		padding: 30px;
		position: relative;
		.close-search-suggestions {
			font-size: 14px;
			border-radius: $border-radius;
			position: absolute;
			top: 50%;
			right: 0px;
			cursor: pointer;
			padding: 2px;
			background-color: $danger;
			color: #fff;
			line-height: 0.9;
			vertical-align: middle;
			transform: translateY(-50%);
			i {
				vertical-align: middle;
			}
			&:hover {
				background-color: darken($danger, 20%);
				color: #fff;
			}
		}
		.element-search {
	    position: relative;
	    margin: 0px;
	    margin-bottom: 30px;
	    &:before {
	      @include osfont();
	      position: absolute;
	      left: 6px;
	      top: 48%;
	      transform: translateY(-50%);
	      font-size: 16px;
	      content: "\e92c";
	      color: rgba(0,0,0,0.3);
	    }
	    input {
	      border: none;
	      box-shadow: none;
	      border-radius: 0px;
	      padding: 8px 15px 8px 31px;
	      display: block;
	      width: 100%;
	      outline: none;
	      border: none;
	      box-shadow: none;
	      border-bottom: 1px solid #111;
	      &::placeholder {
	        color: rgba(0,0,0,0.5);
	      }
	    }
		}
	}
	.search-suggestions-group {
		& + .search-suggestions-group {
			margin-top: 20px;
		}
		&:last-child {
			.ssg-items {
				margin-bottom: 0px;
			}
		}
		.ssg-header {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-bottom: 2px solid rgba(0,0,0,0.07);
			margin-bottom: 15px;
			padding-bottom: 5px;
			.ssg-icon {
				color: $primary;
				font-size: 20px;
				margin-right: 15px;
			}
			.ssg-name {
				color: $primary;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        font-size: $font-size-base * 1.1;
			}
			.ssg-info {
				margin-left: auto;
				@include text-faded();
				font-size: $font-size-sm;
			}
		}
	}
	.ssg-content {
		.ssg-nothing-found {
			@include text-faded();
			padding: 0px 20px;
			text-align: center;
			font-size: $font-size-sm;
			display: none;
			.icon-w {
				margin-bottom: 10px;
				font-size: 20px;
				color: rgba(0,0,0,0.2);
			}
		}
	}
	.ssg-items {
		margin-bottom: 25px;
		.ssg-item {
			.item-media {
			}
			.item-name {
				span {
					background-color: #FFF0B7;
				}
			}
		}

		&.ssg-items-blocks {
			display: flex;
			flex-wrap: wrap;

			.ssg-item {
				flex: 0 0 50%;
				padding: 10px;
				border-bottom: 1px solid rgba(0,0,0,0.05);
				text-align: center;
				&:hover {
					text-decoration: none;
					.item-icon,
					.item-media {
						transform: rotate(-15deg);
					}
					.item-name {
						transform: translateY(-5px);
						color: $link-color;
					}
				}
				&:nth-child(even) {
					border-left: 1px solid rgba(0,0,0,0.05);
				}
				&:last-child,
				&:nth-last-child(2) {
					border-bottom: none;
				}
				& + .ssg-item {
				}
				.item-icon {
					font-size: 20px;
					margin-bottom: 5px;
					color: $primary;
					transition: all 0.2s ease;
				}
				.item-media {
					height: 30px;
					width: 30px;
					margin: 0px auto;
					background-size: cover;
					margin-bottom: 10px;
					border-radius: 50%;
					transition: all 0.2s ease;
				}
				.item-name {
					font-size: $font-size-sm;
					text-decoration: underline;
					color: $body-color;
					transition: all 0.2s ease;
				}
			}
		}
		&.ssg-items-list {
			.ssg-item {
				display: flex;
				align-items: center;
				padding: 0px 10px 7px 0px;
				margin-bottom: 7px;
				border-bottom: 1px solid rgba(0,0,0,0.05);
				&:hover {
					text-decoration: none;
					.item-media {
						transform: scale(1.1);
					}
					.item-name {
						transform: translateX(3px);
						color: $link-color;
					}
				}
				&:last-child {
					border-bottom: none;
				}
				& + .ssg-item {
				}
				.item-media {
					flex: 0 0 30px;
					height: 30px;
					background-size: cover;
					margin-right: 15px;
					border-radius: 50%;
					transition: all 0.2s ease;
				}
				.item-name {
					flex: 1;
					text-align: left;
					color: $body-color;
					transition: all 0.2s ease;
				}
			}
		}
		&.ssg-items-boxed {
			.ssg-item {
				display: flex;
				align-items: center;
				padding: 6px 8px;
				border: 1px solid #C4D1E1;
				box-shadow: 0px 2px 3px rgba(0,0,0,0.07);
				border-radius: $border-radius;
				transition: all 0.2s ease;
				&:hover {
					text-decoration: none;
					border: 1px solid darken(#C4D1E1, 10%);
					box-shadow: 0px 3px 5px rgba(0,0,0,0.12);
					transform: translateY(-2px);
					.item-name {
						color: $link-color;
					}
				}
				& + .ssg-item {
					margin-top: 8px;
				}
				.item-media {
					flex: 0 0 30px;
					height: 30px;
					background-size: cover;
					margin-right: 15px;
				}
				.item-name {
					flex: 1;
					text-align: left;
					color: $body-color;
					transition: all 0.2s ease;
				}
			}
		}
	}
	&.over-search-field {
		position: absolute;
		width: 280px;
		z-index: 999;
		transform: translate(-21px, -5px);
		.search-with-suggestions-modal {
			padding-top: 5px;
	    box-shadow: 0px 30px 60px rgba(0,0,0,0.4);
		}
		.element-search {
		}
	}
	&.screen-center {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0,0,0,0.4);
		.search-with-suggestions-modal {
			top: 15%;
	    margin: 0px auto;
	    width: 350px;
	    position: relative;
	    box-shadow: 0px 30px 60px rgba(0,0,0,0.4);
	    margin-bottom: 20px;
		}
	}
}

.autosuggest-search-activator.autosuggest-active {
}