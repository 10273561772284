/// BREADCRUMBS

.breadcrumb {
  list-style: none;
  margin: 0px;
  padding: 10px 30px 10px 30px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  background-color: transparent;
  letter-spacing: 0.7px;
  li {
    margin-bottom: 0px;
    display: inline-block;
    text-transform: uppercase;
    font-size: $font-size-base * 0.65;
    font-family: $font-family-sans-serif;
    a {
      color: $body-color;
    }
    span {
      color: rgba(0,0,0,0.4);
    }
  }
}
.breadcrumbs + .content-box {
  padding-top: 0px;
}
