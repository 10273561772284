/*
  Top Bar Styles
*/
.all-wrapper > .top-bar {
  border-radius: $global-border-radius $global-border-radius 0px 0px;
}
.content-w > .top-bar {
  padding-left: 0px;
}
.top-bar {
  background-color: $top-bar-light-bg;
  box-shadow: $element-box-shadow;
  padding: 0px 20px 0px 0px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 0px $global-border-radius 0px 0px;
  > ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    text-transform: uppercase;
    color: $body-color;
    font-weight: $font-weight-bold;
    letter-spacing: 2px;
    font-size: $font-size-base * 1.1;
    li {
      display: inline-block;
      a {
        display: inline-block;
        padding: 20px 25px;
        color: rgba(0,0,0,0.25);
        position: relative;
        transition: all 0.2s ease;
        &:after {
          content: "";
          background-color: $primary;
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          width: 0px;
          height: 5px;
          transition: all 0.2s ease;
        }
      }
      &.active, &:hover {
        a {
          color: $body-color;
          text-decoration: none;
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  .fancy-selector-w {
    margin-right: auto;
    align-self: stretch;
  }

  .logo-w.menu-size + .fancy-selector-w {
    margin-left: 0px;
  }

  .logo-w {
    text-align: left;
    a {
      display: inline-block;
    }
    img {
      height: 25px;
      width: auto;
      display: inline-block;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
    img + span {
      margin-left: 20px;
      color: $body-color;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: $font-size-base * 0.9;
      font-weight: $font-weight-bold;
    }
    &.menu-size {
      width: $menu-side-width;
    }
  }


  .top-menu-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    padding: 7px 0px;

    .element-search {
      position: relative;
      margin: 0px 1rem;
      &:before {
        @include osfont();
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        content: "\e92c";
        color: rgba(0,0,0,0.4);
      }
      input {
        border: none;
        box-shadow: none;
        background-color: transparent;
        border-radius: 30px;
        padding: 8px 15px 8px 40px;
        display: block;
        width: 220px;
        outline: none;
        border: none;
        box-shadow: none;
        background: #eee;
        &::placeholder {
          color: rgba(0,0,0,0.5);
        }
      }
    }
  }

  .messages-notifications {
    margin: 0px 1rem;
    position: relative;
    font-size: 24px;
    color: $primary;
    .new-messages-count {
      background-color: $danger;
      color: #fff;
      border-radius: 6px;
      font-weight: $font-weight-bold;
      position: absolute;
      top: -5px;
      right: -12px;
      padding: 4px 4px;
      vertical-align: middle;
      font-size: $font-size-base * 0.8;
      line-height: 1;
    }
    i {
      vertical-align: middle;
    }
  }
  .top-icon {
    margin: 0px 1rem;
    color: $primary;
    font-size: 26px;
    i {
      vertical-align: middle;
    }
  }

  .logged-user-w {
    position: relative;
    padding: 0px 1rem;
    border-bottom: none;
    .avatar-w {
      padding: 0px;
      border: none;
      vertical-align: middle;
      img {
        width: 40px;
      }
    }
    .logged-user-i{
      display: inline-block;
      &:hover {
        cursor: pointer;
        .logged-user-menu {
          visibility: visible;
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }

    .logged-user-menu {
      background: $sub-menu-bright-bg-color;
      box-shadow: 0 10px 30px 0 rgba(27, 30, 35, 0.27);
      position: absolute;
      top: -10px;
      right: -20px;
      overflow: hidden;
      padding: 1rem;
      z-index: 999;
      visibility: hidden;
      opacity: 0;
      transform: translateY(20px);
      transition: all 0.3s ease;
      text-align: left;
      border-radius: $global-border-radius;
      .logged-user-avatar-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        padding: 0px 30px 10px 13px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
      }
      .avatar-w {
        border-color: #fff;
        vertical-align: middle;
        margin-right: 20px;
      }
      .logged-user-info-w {
        vertical-align: middle;
        .logged-user-name {
          color: #fff;
          white-space: nowrap;
        }
        .logged-user-role {
          color: rgba(255,255,255,0.6);
          white-space: nowrap;
        }
      }
      ul {
        list-style: none;
        text-align: left;
        margin: 0px;
        padding: 0px;
        padding-bottom: 0px;
        li {
          border-bottom: 1px solid rgba(255,255,255,0.05);
          a {
            display: block;
            padding: 10px 20px;
            color: #fff;
            i {
              vertical-align: middle;
              margin-right: 15px;
              font-size: 20px;
              transition: all 0.2s ease;
              display: inline-block;
            }
            span {
              vertical-align: middle;
              font-size: $font-size-base;
              transition: all 0.2s ease;
              display: inline-block;
            }
            &:hover {
              text-decoration: none;
              i {
                transform: translateX(5px);
              }
              span {
                transform: translateX(8px);
              }
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
      .bg-icon {
        font-size: 100px;
        color: rgba(255,255,255,0.1);
        position: absolute;
        bottom: -40px;
        right: -20px;
      }
    }
  }

  &.color-scheme-light {
  }
  &.color-scheme-transparent {
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid rgba(0,0,0,0.05);
  }
  
  &.color-scheme-light,
  &.color-scheme-transparent {
    .top-menu-controls .element-search input {
      background-color: rgba(121, 138, 185, 0.07);
    }
    .logo-w {
      .logo-label {
        color: $body-color;
      }
      .logo-element {
        background-color: #0a7cf8;
        &:after {
          background-color: #bfd6f9;
        }
      }
      .logo:hover {
        .logo-label {
          color: $primary;
        }
        .logo-label:after {
          background-color: $primary;
        }
      }
    }
  }

  &.color-scheme-dark,
  &.color-scheme-bright {
    box-shadow: none;
    .top-menu-controls .element-search input {
      background-color: rgba(0,0,0,0.2);
      color: #fff;
      &::placeholder {
        color: rgba(255,255,255,0.3);
      }
    }
    .top-menu-controls .element-search:before {
      color: rgba(255,255,255,0.7);
    }
    .messages-notifications,
    .top-icon {
      color: #fff;
    }
    > ul {
      li {
        a {
          color: rgba(255,255,255,0.4);
          &:after {
            background-color: $highlight-accent;
            border-color: $highlight-accent;
          }
        }
        &.active, &:hover {
          a {
            color: #fff;
          }
        }
      }
    }

    .logo-w {
      img + span {
        color: #fff;
      }
    }
    .fancy-selector-w .fancy-selector-current .fs-selector-trigger {
      background-color: lighten($fancy-selector-bg-dark, 5%);
    }
  }
  &.color-scheme-dark {
    background-color: $top-bar-dark-bg;
  }
  &.color-scheme-bright {
    background-color: $top-bar-bright-bg;
  }
}