.emergency-floating-button {
    z-index: 9999;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #FF002F;
    border-radius: 40px;
    box-shadow: 0 2px 20px 0 #ff0000;
    display: flex;
    cursor: pointer;
    user-select: none;
    padding: 10px 30px;
}

.monitoring-floating-button {
    z-index: 9999;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #FF7600;
    border-radius: 40px;
    box-shadow: 0 2px 20px 0 #FF7600;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    user-select: none;
    padding: 10px 30px;
}

.emergency-image {
    width: 55px;
    height: 55px;
    resize: both;
    padding-left: 6px auto;
    align-self: center;
}

.emergency-name {
    font-size: 1rem;
    color: #fff;
    font-weight: 700;
    align-self: center;
    padding-right: 1rem;
}

ul.pagination>li {
    .active {
        background-color: blue;
        color: white;
    }
}

ul.pagination>li {
    border: 1px solid transparent;
    border-radius: 13px;
    padding: 18px;
    background: aliceblue;
    margin: 5px
}


.react-responsive-modal-closeButton {
    position: absolute;
    top: -4px !important;
    right: 0px !important;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
}

.filterAlign {
    margin-left: 0 !important;
}

.filterCheckbox {
    margin-left: 6px;
    display: inline-block;
}

// 